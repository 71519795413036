import imgBrandLogo from './storybookStatic/brand_logo.png';
import imgATNT from './storybookStatic/atnt.png';

export const menu = [
  {
    id: '932dbc9e-7b92-48c4-a4fc-28b111fd3fa1',
    label: 'What to Watch',
    to: '/',
    children: [
      {
        id: '6078ff81-13b1-44d3-8d86-3b7a97857cd1',
        label: "What's New",
        to: '/#new-titles',
      },
      {
        id: 'cfd1e79c-b32e-43d0-a9f8-4221444a07e8',
        label: "What's Coming",
        to: '/#featured-titles',
      },
      {
        id: '883d1bf0-971e-4c58-9182-884d389203f0',
        label: 'Did You Know',
        to: '/#coach-corner',
      },
      {
        id: '06da808b-0f90-4a59-a744-9442e4012e4b',
        label: 'Entertainment Categories',
        to: '/#selected-categories',
      },
      {
        id: '06da808b-0f90-4a59-a744-9442e4012e4q',
        label: 'What People Are Saying',
        to: '/#water-cooler',
      },
    ],
  },
  {
    id: '09ea2380-3ad0-4e7b-88d1-7709ee106e02',
    label: 'Activate Max',
    to: '/how-to-sell',
    children: [
      {
        id: '7f0860d4-b48d-4641-a99a-ec206a09fb74',
        label: 'Step By Step',
        to: '/how-to-sell/#how-to-access',
      },
      {
        id: 'bb19052d-fd32-4572-9984-0e0e46ac83e6',
        label: 'Supported Devices',
        to: '/how-to-sell/#available-devices',
      },
      {
        id: '883d1bf0-971e-4c58-9182-8842d389203f0',
        label: 'FAQ',
        to: '/how-to-sell/#faq-general',
      },
      // {
      //   id: '883d1bf0-971e-4c58-9182-8842d389203f0',
      //   label: 'FAQ | General',
      //   to: '/how-to-sell/#faq-general',
      // },
      // {
      //   id: '883d1bf0-971e-4c58-9182-8842d389203f1',
      //   label: 'FAQ | Live Sports',
      //   to: '/how-to-sell/#faq-live-sports',
      // },
      // {
      //   id: '883d1bf0-971e-4c58-9182-8842d389203f2',
      //   label: 'FAQ | Live News',
      //   to: '/how-to-sell/#faq-live-news',
      // },
    ],
  },
  {
    id: '6ad5c944-8aca-4a85-baa8-462387462834',
    label: 'Training Hub',
    to: '/training',
  },
  {
    id: '6ad5c944-8aca-4a85-baa8-0a7bd1c24765',
    label: 'En Español',
    to: '/spanish',
    children: [
      {
        id: '7f0860d4-b48d-4641-a99a-ec206a09fb74',
        label: 'Consejos de ventas', // Sales Tips
        to: '/spanish/#coach-corner',
      },
      {
        id: 'bb19052d-fd32-4572-9984-0e0e46ac83e6',
        label: 'Instrucciones', // Step By Step
        to: '/spanish/#how-to-access',
      },
      {
        id: '883d1bf0-971e-4c58-9182-8842d389203f01',
        label: 'Dispositivos', // Supported Devices
        to: '/spanish/#available-devices',
      },
      {
        id: '883d1bf0-971e-4c58-9182-8842d389203f02',
        label: 'Videos tutoriales', // Training Videos
        to: '/spanish/#video-promos',
      },
      {
        id: '883d1bf0-971e-4c58-9182-8842d389203f03',
        label: 'Preguntas frecuentes', // FAQ
        to: '/spanish/#preguntas-frecuentes',
      },
    ],
  },
  {
    id: 'ddfe1d13-27ab-4de4-be0e-262716fcd9e6',
    label: 'Have Some Fun',
    to: '/have-some-fun',
    children: [
      {
        id: '2d3ff7e2-6c4f-42ab-8547-b3f9bd51cb49',
        label: 'Play & Learn',
        to: '/have-some-fun/#activity-section',
      },
      {
        id: '1f60d032-f2d7-4a1e-a26b-2f87c801f612',
        label: 'Downloads',
        to: '/have-some-fun/#downloads',
      },
    ],
  },
];

export const brandLogo = imgBrandLogo;
export const brandName = 'HBO Max';
export const partnerLogo = imgATNT;
export const partnerName = 'AT&T';
